<template>
  <div class="CaseMainTactics">
    <div class="addTactics">
      <el-button type='primary' icon='el-icon-plus' 
      size="small" round 
      @click='addCelue'>策略</el-button>
    </div>
    <div class="divPart" v-for='(item,idx) in listData' :key='item.strategyId'>
      <div class="partTitle">
        <div class="titleText">策略{{idx+1}}</div>
        <div class="iconBox">
          <div class="el-icon-edit" @click='getDetails(item.strategyId)'></div>
          <div class="el-icon-delete" @click='deletItem(item.strategyId)'></div>
        </div>
      </div>
      <div class="partContent">
        <div class="item">
          <div class="conTitle">问题描述</div>
          <div class="conText">{{item.description}}</div>
        </div>
        <div class="item">
          <div class="conTitle">解决方案</div>
          <div class="conText">{{item.solution}}</div>
        </div>
        <div class="item">
          <div class="conTitle">执行结果</div>
          <div class="conText">{{item.result}}</div>
        </div>
      </div>
    </div>
    <myDialog
    @dialogAfterLeave='afterLeave'
    :title='tacticsOppTitle'
    :isShow='baseOppIsShow'
    :closeDialog='closeDialog'>
    <div class='tacticsBox' style='padding:30px'>
      <el-form ref="myform"
      :model="form" 
      label-width="120px">
        <el-form-item label="问题描述"
        prop='description'
        :rules="{
          required: true, message: '问题描述不能为空', trigger: 'blur'
        }">
          <el-input size='small' style='width:350px'
          v-model="form.description" clearable></el-input>
        </el-form-item>
        <el-form-item label="解决方案"
        prop='solution'>
          <el-input size='small' style='width:350px' type='textarea'
          v-model="form.solution" clearable></el-input>
        </el-form-item>
        <el-form-item label="执行结果"
        prop='result'>
          <el-input size='small' style='width:350px'
          v-model="form.result" clearable></el-input>
        </el-form-item>
        <div class="btnFooter">
          <el-button size='small' type="primary" plain
          @click='closeDialog'>取消</el-button>
          <el-button size='small' type="primary" style='margin-left:20px'
          @click='saveForm'>保存</el-button>
        </div>
      </el-form>
    </div>
    </myDialog>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import myDialog from '@/components/myDialog.vue';
import {saveCaseStrategy,getCaseStrategyList,deleteCaseStrategy,getCaseStrategyInfo} from '@/api';
export default defineComponent({
  name:"CaseTactics",
  created(){
    this.getList()
  },
  data(){
    return {
      tacticsOppTitle:'新增办案策略',
      baseOppIsShow:false,
      form:{
        description:'',
        result:'',
        solution:'',
        strategyId:''
      },
      listData:[],
    }
  },
  components:{
    myDialog
  },
  methods:{
    async getDetails(strategyId){
      this.baseOppIsShow = true;
      let res = await getCaseStrategyInfo({
        strategyId
      });
      if(res&&res.code==200){
        this.form.description = res.data.description;
        this.form.strategyId = res.data.strategyId;
        this.form.result = res.data.result;
        this.form.solution = res.data.solution;
      }
    },
    async deletItem(strategyId){
      let res = await deleteCaseStrategy({
        strategyId
      });
      if(res&&res.code==200){
        this.getList();
        this.$message({
          type: 'success',
          message: '删除成功'
        })
      }
    },
    async getList(){
      let res = await getCaseStrategyList({
        caseId:this.$route.params.caseId
      });
      if(res&&res.code==200){
        this.listData = res.data.caseStrategyInfoVOList
      }
    },
    afterLeave(){
      this.baseOppIsShow = false
    },
    closeDialog(){
      this.$refs['myform'].resetFields();
      this.baseOppIsShow = false;
    },
    async addApi(data){
      let res = await saveCaseStrategy(data);
      if(res&&res.code==200){
        this.closeDialog();
        this.getList();
        this.$message({
          type: 'success',
          message: '添加成功'
        })
      }
    },
    addCelue(){
      this.baseOppIsShow = true;
      this.form.strategyId = '';
    },
    saveForm(){
      this.$refs['myform'].validate(val=>{
        if(val){
          this.addApi({
            caseId:this.$route.params.caseId,
            description:this.form.description,
            result:this.form.result,
            solution:this.form.solution,
            strategyId:this.form.strategyId
          })
        }
      })
    },
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.CaseMainTactics{
  padding-bottom:30px;
  .addTactics{
    padding:20px 30px;
    display: flex;
    justify-content: flex-end;
  }
  .divPart{
    width:100%;
    box-sizing: border-box;
    padding:0 30px;
    margin-bottom:30px;
    &:last-child{
      margin-bottom:0;
    }
    .partTitle{
      box-sizing: border-box;
      padding:0 20px;
      width:100%;
      height:40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) -32%, rgba(255, 134, 0, 1) 335%);
      box-shadow: 5px 5px 5px rgba(220, 223, 230, 1);
      .titleText{
        font-size: 16px;
        color:#666666;
      }
      .iconBox{
        font-size: 20px;
        color:#666;
        div{
          &:hover{
            cursor: pointer;
            color:$main-color;
          }
          &:first-child{
            margin-right: 10px;
          }
        }
      }
    }
    .partContent{
      margin-top:20px;
      width:100%;
      box-sizing: border-box;
      padding:30px 20px;
      border-radius: 10px;
      box-shadow: 2px 2px 5px rgba(220, 223, 230, 1);
      .item{
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom:20px;
        &:last-child{
          margin-bottom:0;
        }
        .conTitle{
          font-size: 14px;
          color:#666;
          margin-right: 20px;
          width:66px;
        }
        .conText{
          width:990px;
          @include ellipsis;
          font-size: 14px;
          color:#333;
        }
      }
    }
  }
}
.btnFooter{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin:30px auto 0 auto;
}
</style>